import { BUTTON_TYPES, PLATFORMS } from '../constants'

export type IGeneralLinks = {
  [key in PLATFORMS | 'blog' | 'about' | 'contact']: {
    name: string,
    link: string
    usaAcc?: string
    globalAcc?: string
    usaApp?: string
    globalApp?: string
    accountLink?: string
    actions?: {
      [key in BUTTON_TYPES]?: string
    }
  }
}

export const GENERAL_LINKS: IGeneralLinks = {
  blog: {
    name: 'BLOG',
    link: 'https://sky-tide.com/blog'
  },
  about: {
    name: 'ABOUT US',
    link: 'http://gramatik.site/'
  },
  contact: {
    name: 'CONTACT',
    link: 'http://gramatik.site/'
  },
  [PLATFORMS.PLUS500]: {
    name: 'Plus500',
    link: '',
  },
  [PLATFORMS.ETORO]: {
    name: 'eToro',
    link: 'https://med.etoro.com/B12087_A118311_TClick.aspx',
  },
  [PLATFORMS.PEPPERSTONE]: {
    name: 'Pepperstone',
    link: 'https://pepperstone.sjv.io/4PZDY3?subId1=main',
  },
  [PLATFORMS.BLACKBULL_MARKETS]: {
    name: 'BlackBull Markets',
    link: 'https://go.blackbull.com/visit/?bta=40636&brand=blackbull',
  },
  [PLATFORMS.AVA_TRADE]: {
    name: 'AvaTrade',
    link: 'https://trakverse.top/click.php?project_id=64cc565faa&affiliate_id=249c1b0b97&custom2=broker-rating&dh=d494ea378b',
  },
  [PLATFORMS.INTER_TRADER]: {
    name: 'InterTrader',
    link: 'https://www.intertrader.com/?affid=7222062536'
  },
  [PLATFORMS.EXNESS]: {
    name: 'Exness',
    link: 'https://trakverse.top/click.php?project_id=560974b09f&affiliate_id=249c1b0b97&lp=935a3da779&custom2=broker-rating&dh=d494ea378b'
  },
  [PLATFORMS.YOU_HODLER]: {
    name: 'YouHodler',
    link: ''
  }
}

export type IAppAccLinks = {
  [key in PLATFORMS]: {
    [key in BUTTON_TYPES]?: string
  }
}

export const APP_ACC_LINKS: IAppAccLinks = {
  [PLATFORMS.PLUS500]: {
    [BUTTON_TYPES.GET_APP_GLOBAL_LINK]: '',
    [BUTTON_TYPES.GET_APP_USA_LINK]: '',
    [BUTTON_TYPES.OPEN_ACCOUNT_GLOBAL_LINK]: '',
    [BUTTON_TYPES.OPEN_ACCOUNT_USA_LINK]: '',
    //
    // [BUTTON_TYPES.GET_APP_GLOBAL_LINK]: 'https://www.plus500.com/en/multiplatformdownload?product=CFD&id=135457&tags=sky-tide&pl=2',
    // [BUTTON_TYPES.GET_APP_USA_LINK]: 'https://us.plus500.com/en/multiplatformdownload?product=Futures&id=135457&tags=sky-tide&pl=2',
    // [BUTTON_TYPES.OPEN_ACCOUNT_GLOBAL_LINK]: 'https://www.plus500.com/en/?id=135457&tags=sky-tide&pl=2',
    // [BUTTON_TYPES.OPEN_ACCOUNT_USA_LINK]: 'https://us.plus500.com/en/?id=135457&tags=sky-tide&pl=2',
  },
  [PLATFORMS.ETORO]: {
    [BUTTON_TYPES.OPEN_ACCOUNT_GLOBAL_LINK]: 'https://med.etoro.com/B12087_A118311_TClick.aspx',
    [BUTTON_TYPES.OPEN_ACCOUNT_USA_LINK]: 'https://med.etoro.com/B12690_A121635_TClick.aspx',
  },
  [PLATFORMS.PEPPERSTONE]: {
    [BUTTON_TYPES.OPEN_ACCOUNT_GLOBAL_LINK]: 'https://pepperstone.sjv.io/4PZDY3?subId1=main',
  },
  [PLATFORMS.BLACKBULL_MARKETS]: {
    [BUTTON_TYPES.OPEN_ACCOUNT_GLOBAL_LINK]: 'https://go.blackbull.com/visit/?bta=40636&brand=blackbull',
  },
  [PLATFORMS.AVA_TRADE]: {
    [BUTTON_TYPES.OPEN_ACCOUNT_GLOBAL_LINK]: 'https://trakverse.top/click.php?project_id=64cc565faa&affiliate_id=249c1b0b97&custom2=broker-rating&dh=d494ea378b',
    [BUTTON_TYPES.APP_STORE_LINK]: 'https://app.appsflyer.com/id1247935193?pid=broker-rating&af_prt=gramatikltil975&c=broker-rating&af_sub1=185503',
    [BUTTON_TYPES.GOOGLE_PLAY_LINK]: 'https://app.appsflyer.com/com.avatrade.mobile?pid=broker-rating&af_prt=gramatikltil975&c=broker-rating&af_sub1=185503',
  },
  [PLATFORMS.INTER_TRADER]: {
    [BUTTON_TYPES.OPEN_ACCOUNT_GLOBAL_LINK]: 'https://www.intertrader.com/?affid=7222062536',
  },
  [PLATFORMS.EXNESS]: {
    [BUTTON_TYPES.OPEN_ACCOUNT_GLOBAL_LINK]: 'https://trakverse.top/click.php?project_id=560974b09f&affiliate_id=249c1b0b97&lp=935a3da779&custom2=broker-rating&dh=d494ea378b',
    [BUTTON_TYPES.APP_STORE_LINK]: 'https://app.appsflyer.com/id1359763701?pid=broker-rating&af_prt=gramatikltil975&af_sub3=c_8oz39nmwn6&agent=c_8oz39nmwn6&utm_source=broker-rating&sub_id=broker-rating&c=gramatikltil975_%7B%7D_broker-rating_agent_c_8oz39nmwn6',
    [BUTTON_TYPES.GOOGLE_PLAY_LINK]: 'https://app.appsflyer.com/com.exness.android.pa?pid=broker-rating&af_prt=gramatikltil975&af_sub3=c_8oz39nmwn6&agent=c_8oz39nmwn6&utm_source=broker-rating&sub_id=broker-rating&c=gramatikltil975_%7B%7D_broker-rating_agent_c_8oz39nmwn6',
  },
  [PLATFORMS.YOU_HODLER]: {
    [BUTTON_TYPES.APP_STORE_LINK]: 'https://youhodler.onelink.me/IPBU?af_xp=custom&pid=universal&af_prt=gramatikltil975',
    [BUTTON_TYPES.GOOGLE_PLAY_LINK]: 'https://youhodler.onelink.me/IPBU?af_xp=custom&pid=universal&af_prt=gramatikltil975',
  }
}

export enum REDDIT_ENUM {
  GramatikInsights = 'GramatikInsights',
  Gramatik = 'Gramatik'
}

export type IRedditLinks = {
  [key in REDDIT_ENUM]: {
    name: string,
    link: string
  }
}
export const REDDIT_LINKS: IRedditLinks = {
  [REDDIT_ENUM.GramatikInsights]: {
    name: 'XGramatikInsights',
    link: 'https://www.reddit.com/r/XGramatikInsights'
  },
  [REDDIT_ENUM.Gramatik]: {
    name: 'u/XGramatik',
    link: 'https://www.reddit.com/r/XGramatikInsights'
  },
}
