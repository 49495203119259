import React from 'react'
import { Link } from 'react-router-dom'

import { PLATFORMS, VIEW_TYPE } from '../../../../assets/constants'
import { TEXTS } from '../../../../assets/content/texts'
import { GENERAL_LINKS } from '../../../../assets/content/links'
import { URLS } from '../../../../App'
import { IMain } from '../../index'
import { Reddit } from '../Reddit'
import { DesktopCard } from '../Platform/DesktopCard'

import '../../styles.css'

interface IStandardView {
  openModal: IMain['openModal'],
  view: IMain['view'],
}

export const StandardView = ({ openModal, view }: IStandardView) => (
  <>
    <div className="Top">
      <div className="TopLeft">
        <div className="Logo" />
        <div className="Disclaimer">
          { TEXTS.disclaimer }
        </div>
      </div>
      <div className="TopRight">
        <div className="Link">
          <Link to={GENERAL_LINKS.blog.link} target='_blank' rel="noreferrer">{GENERAL_LINKS.blog.name}</Link>
        </div>
        <div className="Link">
          <Link to={GENERAL_LINKS.about.link} target='_blank' rel="noreferrer">{GENERAL_LINKS.about.name}</Link>
        </div>
        <div className="Link">
          <Link to={GENERAL_LINKS.contact.link} target='_blank' rel="noreferrer">{GENERAL_LINKS.contact.name}</Link>
        </div>
      </div>
    </div>
    <div className="Title">
      {TEXTS.header1}
      <br />
      {TEXTS.header2}
    </div>
    <div className="Intro Card">{TEXTS.intro}</div>
    {view === VIEW_TYPE.TABLET && (
      <div className="AdvertisementInIntro">
        <Reddit />
      </div>
    )}
    <div className="Content">
      <div className="Platforms">
        {Object.values(PLATFORMS).map((platform) => (
          <DesktopCard key={platform} platform={platform} openModal={openModal} />
        ))}
      </div>

      {view === VIEW_TYPE.DESKTOP && (
        <div className="AdvertisementRight">
          <Reddit />
        </div>
      )}
    </div>
      <div className="FooterIntro">
        <div className="CardLabel">{TEXTS.ratedBasedOn}</div>
        <div className="Card">{TEXTS.ratingDescription}</div>
      </div>
      <div className="Footer">
        <Link to={URLS.conditions()} target="_blank" rel="noopener noreferrer">{TEXTS.conditions}</Link>
        <Link to={URLS.privacyPolicy()} target="_blank" rel="noopener noreferrer">{TEXTS.privacyPolicy}</Link>
    </div>
  </>
)

