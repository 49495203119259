import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { termsAndConditionsTexts, privacyPolicyTexts } from './assets/content/conditions'
import Conditions from './root/conditions/Conditions'
import './App.css'
import Main from './root/main'

export const URLS = {
  root: () => '/',
  conditions: () => `/conditions`,
  privacyPolicy: () => `/privacyPolicy`
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={URLS.conditions()} element={<Conditions content={termsAndConditionsTexts} />} />
        <Route path={URLS.privacyPolicy()} element={<Conditions content={privacyPolicyTexts} />} />
        <Route path={URLS.root()} element={<Main />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
