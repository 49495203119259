import React from 'react'
import { Link } from 'react-router-dom'

import { PLATFORMS } from '../../../../../assets/constants'
import { PLATFORMS_INFO } from '../../../../../assets/content/texts'
import { TEXTS } from '../../../../../assets/content/texts'
import { RatingStars } from '../../RatingStars'

import '../styles.css'

import { Buttons } from '../../Buttons'
import { IMain } from '../../../index'

interface IDesktopCard {
  platform: PLATFORMS,
  openModal: IMain['openModal'],
}

export const DesktopCard = ({ platform, openModal }: IDesktopCard) => {
  const {
    name,
    link,
    score,
    founded,
    deposit,
    impact,
    tools,
    regulation,
    platforms,
    color,
    intro,
    securitiesTrading,
    companyPresence,
    futuresRegulation,
    actions
  } = PLATFORMS_INFO[platform]

  return (
    <div className="Platform">
      <div className="ImageHolder">
        <div className="Background" style={{ backgroundColor: color }} />
        <div className={`Image ${platform}`} />
      </div>
      <div className={`ContentHolder For_${platform}`}>
        <div className="TitleRow">
          <div className="DesktopTitle">
            <Link to={link} target='_blank' rel="noreferrer">{name}</Link>
          </div>
          <RatingStars score={score} />
        </div>
        <div className="Row">
          <div>
            <span className="GreenBold">{TEXTS.founded}</span>
            {founded}
          </div>
          <div>
            <span className="ExtraBold">{TEXTS.minDeposit}</span>
            <span className="YellowBold">{deposit}</span>
          </div>
        </div>
        <div>
          <span className="GreenBold">{TEXTS.makeAnImpact}</span>
          {impact}
        </div>
        { actions && <Buttons actions={actions} platform={platform} openModal={openModal} /> }
        { intro && <div className="PlatformIntro">{intro}</div>}

        <div className="ValueTools">
          <span className="GreenBold">{TEXTS.addingValueTools}</span>
          {tools}
        </div>
        { regulation && (
          <div className="Regulations">
            <span className="GreenBold">
              {TEXTS.regulation}
              {(platform === PLATFORMS.ETORO  || platform === PLATFORMS.PLUS500)&& TEXTS.CFDs}
            </span>
            {regulation}
            {securitiesTrading && (
              <>
                <span className="GreenBold">
                  {TEXTS.securitiesTrading}
                </span>
                {securitiesTrading}
              </>
            )}
            {futuresRegulation && (
              <>
                <span className="GreenBold">
                  {TEXTS.futures}
                </span>
                {futuresRegulation}
              </>
            )}
          </div>
        )}
        { platforms && (
          <div className="TradingPlatforms">
            <span className="GreenBold">{TEXTS.tradingPlatforms}</span>
            {platforms}
          </div>
        )}
        { companyPresence && (
          <div className="companyPresence">
            <span className="GreenBold">{TEXTS.companyPresence}</span>
            {companyPresence}
          </div>
        )}
      </div>
    </div>
  )
}
