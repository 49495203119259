import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'

import { PLATFORMS } from '../../../../../assets/constants'
import { PLATFORMS_INFO } from '../../../../../assets/content/texts'
import { ReactComponent as InfoIcon } from '../../../../../assets/icons/info.svg'
import { ReactComponent as BackIcon } from '../../../../../assets/icons/back.svg'
import { TEXTS } from '../../../../../assets/content/texts'

import { IMain } from '../../../index'
import { RatingStars } from '../../RatingStars'
import { Buttons } from '../../Buttons'

import '../styles.css'


interface IDesktopCard {
  platform: PLATFORMS,
  openModal: IMain['openModal'],
}

export const MobileCard = ({ platform, openModal }: IDesktopCard) => {
  const {
    name,
    link,
    score,
    founded,
    deposit,
    impact,
    impactMobile,
    tools,
    regulation,
    futuresRegulation,
    securitiesTrading,
    platforms,
    companyPresence,
    color,
    actions
  } = PLATFORMS_INFO[platform]

  const [flip, setFlip] = useState(false)

  const handleInfo = useCallback(() => setFlip(true), [])
  const handleBack = useCallback(() => setFlip(false), [])

  return (
    <div className={`CardAnimation ${flip ? 'Flipped' : ''}`}>
      <div className="Platform FrontSide">
        <div className="ImageHolder">
          <div className="Background" style={{ backgroundColor: color }} />
          <div className={`Image ${platform}`} />
        </div>
        <div className={`ContentHolder For_${platform}`}>
          <div className="Title">
            <Link to={link} target='_blank' rel="noreferrer">{name}</Link>
            <InfoIcon onClick={handleInfo} />
          </div>
          <div className="ImpactText">
            {impactMobile || impact}
          </div>
          {actions && <Buttons actions={actions} platform={platform} openModal={openModal} />}
          <div className={`FooterRow ${deposit.length > 10 ? 'DepositTop' : ''}`}>
            <div className="Deposit">
              <div className="ExtraBold">{TEXTS.minDeposit}</div>
              <div className="YellowBold">{deposit}</div>
            </div>
            <RatingStars score={score} />
          </div>
        </div>
      </div>

      <div className="Platform BackSide">
        <div className="ContentHolder">
          <div className="CenteredBackRow">
            <BackIcon onClick={handleBack} />
            <div className="CenteredBackLink">
              <Link to={link} target='_blank' rel="noreferrer">{name}</Link>
            </div>
          </div>
          <div className="BackInfo">
            <div>
              <span className="GreenBold">{TEXTS.founded}</span>
              {founded}
            </div>
            <div>
              <span className="GreenBold">{TEXTS.addingValueTools}</span>
              {tools}
            </div>
            { regulation && (
              <div>
                <span className="GreenBold">
                  {TEXTS.regulation}
                  {(platform === PLATFORMS.ETORO || platform === PLATFORMS.PLUS500) && TEXTS.CFDs}
                </span>
                {regulation}
                {securitiesTrading && (
                  <>
                <span className="GreenBold">
                  {TEXTS.securitiesTrading}
                </span>
                    {securitiesTrading}
                  </>
                )}
                {futuresRegulation && (
                  <>
                <span className="GreenBold">
                  {TEXTS.futures}
                </span>
                    {futuresRegulation}
                  </>
                )}
              </div>
            )}
            { platforms && (
              <div><span className="GreenBold">{TEXTS.tradingPlatforms}</span>{platforms}</div>
            )}
            { companyPresence && (
              <div>
                <span className="GreenBold">{TEXTS.companyPresence}</span>
                {companyPresence}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
