export enum PLATFORMS {
  PLUS500 ='PLUS500',
  ETORO = 'ETORO',
  PEPPERSTONE = 'PEPPERSTONE',
  BLACKBULL_MARKETS = 'BLACKBULL_MARKETS',
  AVA_TRADE = 'AVA_TRADE',
  INTER_TRADER = 'INTER_TRADER',
  EXNESS = 'EXNESS',
  YOU_HODLER = 'YOU_HODLER',
}

export enum BUTTON_TYPES {
  GET_APP_BUTTON = 'GET_APP_BUTTON',
  OPEN_ACCOUNT_BUTTON = 'OPEN_ACCOUNT_BUTTON',

  OPEN_ACCOUNT_GLOBAL_LINK = 'OPEN_ACCOUNT_GLOBAL_LINK',
  OPEN_ACCOUNT_USA_LINK = 'OPEN_ACCOUNT_USA_LINK',

  GET_APP_GLOBAL_LINK = 'GET_APP_GLOBAL_LINK',
  GET_APP_USA_LINK = 'GET_APP_USA_LINK',

  APP_STORE_LINK = 'GET_APP_APP_STORE_LINK',
  GOOGLE_PLAY_LINK = 'GET_APP_GOOGLE_PLAY_LINK',
}


export enum ACTION_TYPES {
  GET_APP = 'GET_APP',
  OPEN_ACCOUNT = 'OPEN_ACCOUNT',
}

export enum VIEW_TYPE {
  DESKTOP = 'DESKTOP',
  TABLET = 'TABLET',
  MOBILE = 'MOBILE',
}
