import React, { useEffect } from 'react'
import { REDDIT_ENUM, REDDIT_LINKS } from '../../../../assets/content/links'
import { TEXTS } from '../../../../assets/content/texts'

export const Reddit = () => {
  // Это часть, где подключается скрипт реддита
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "//embed.redditmedia.com/widgets/platform.js"
    script.async = true
    document.body.appendChild(script)
  }, [])

  return (
    <div className="Card">
      <div className="AdsTitle">Join Our Reddit</div>
      {/* Это часть, где прописываем пути  */}
      <blockquote className="reddit-embed-bq">
        <a href={REDDIT_LINKS[REDDIT_ENUM.GramatikInsights].link}>{TEXTS.redditHeader}</a>
        <br/>
        by
        <a href={REDDIT_LINKS[REDDIT_ENUM.Gramatik].link}>{REDDIT_LINKS[REDDIT_ENUM.Gramatik].name}</a>
        in
        <a href={REDDIT_LINKS[REDDIT_ENUM.GramatikInsights].link}>{REDDIT_LINKS[REDDIT_ENUM.GramatikInsights].name}</a>
      </blockquote>
    </div>
  )
}
