import React, { useCallback } from 'react'
import uniqid from 'uniqid'
import { Link } from 'react-router-dom'
import { textTypes } from '../../assets/content/conditions'
import { URLS } from '../../App'
import './styles.css'

const Header = ({children}) => <h2>{children}</h2>
const SubHeader = ({children}) => <h4>{children}</h4>
const Paragraph = ({ children }) => (
  <p>
    {children.bold && typeof children.bold === 'string' && <b>{children.bold}</b>}
    {children.text || children}
    {children.link && typeof children.link === 'string' && <Link to={URLS.privacyPolicy()} target="_blank" rel="noopener noreferrer">{children.link}</Link>}
  </p>
)
const OrderedList = ({children}) => (
  <ol>
    {Object.values(children).map((li) => (
      <li key={uniqid()}>
        <b>{li.name}</b>
        {li.info}
      </li>
    ))}
  </ol>
)

const UnorderedList = ({children}) => (
  <ul>
    {Object.values(children).map((li) => (
      <li key={uniqid()}>
        <b>{li.name}</b>
        {li.link && <Link to={URLS.privacyPolicy()} target="_blank" rel="noopener noreferrer">{li.link}</Link>}
        {li.info}
      </li>
    ))}
  </ul>
)
const ComplexLists = ({children}) => (
  <ol>
    {Object.values(children).map((li) => (
      <li key={uniqid()}>
        <b>{li.name}</b>
        <p>{li.info}</p>
        <UnorderedList>{li.content}</UnorderedList>
      </li>
    ))}
  </ol>
)

const Conditions = ({ content }) => {
  const renderItem = useCallback((item) => {
    const textComponentTypes = {
      [textTypes.header]: Header,
      [textTypes.subheader]: SubHeader,
      [textTypes.paragraph]: Paragraph,
      [textTypes.orderedList]: OrderedList,
      [textTypes.unorderedList]: UnorderedList,
      [textTypes.unorderedListInsideOrdered]: ComplexLists
    }

    const Component = textComponentTypes[item.textType]

    return <Component key={uniqid()} children={item.content} />
  }, [])


  return (
    <div className="Container">
      {content.map(renderItem)}
    </div>
  )
}

export default Conditions;
