import { ACTION_TYPES, BUTTON_TYPES, PLATFORMS } from '../constants'
import { GENERAL_LINKS } from './links'

export type ITexts = {
  [key in ACTION_TYPES | 'disclaimer' | 'intro' | 'conditions' | 'privacyPolicy' | 'header1' | 'header2' | 'usa' | 'global' | 'ratedBasedOn' | 'ratingDescription' | 'redditHeader' | 'joinReddit' | 'mobileSubHeader' | 'minDeposit' | 'founded' | 'makeAnImpact' | 'addingValueTools' | 'tradingPlatforms' |
    'regulation' | 'CFDs' | 'futures' | 'securitiesTrading' | 'companyPresence']: string
}

export const TEXTS: ITexts = {
  disclaimer: 'CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. Between 74-89% of retail investor accounts lose money when trading CFDs. You should consider whether you can afford to take the high risk of losing your money.',
  intro: 'In the competitive Forex and Crypto arena, some brokers stand out not just for their market dominance but also for their positive global impact. At Gramatik, we focus on these remarkable firms: trusted, regulated, and committed to making a difference. Explore our handpicked Forex and Crypto leaders who blend financial excellence with ethical influence. Trade with confidence and integrity, and elevate your trading journey beyond just making transactions. Always looking for something better? Experience the trading satisfaction you deserve without any more letdowns.',
  ratingDescription: 'This rating is aimed to celebrate those Forex and Crypto Brokers who are in pursuit of ongoing real change for our planet, society and in how we govern for the better. Investing with these Brokers might not only give you access to the global markets but also make a real impact while you trade.',
  conditions: 'Terms and Conditions',
  privacyPolicy: 'Privacy Policy',
  header1: 'Best Trading Platforms 2024',
  header2: 'Forex, Futures, Securities, Options, Crypto',
  [ACTION_TYPES.GET_APP]: 'GET APP',
  [ACTION_TYPES.OPEN_ACCOUNT]: 'OPEN ACCOUNT',
  usa: 'USA',
  global: 'Global',
  ratedBasedOn: 'The 5 star rating based on Trustpilot reviews',
  redditHeader: 'Best Forex & Crypto Platforms 2024',
  joinReddit: 'Join Our Reddit',
  mobileSubHeader: 'Investing with these Brokers might  not only give you access to the global markets but also make a real impact while you trade',
  minDeposit: 'Min deposit: ',
  founded: 'Founded: ',
  makeAnImpact: 'Make an Impact: ',
  addingValueTools: 'Adding Value Tools: ',
  tradingPlatforms: 'Trading platforms: ',
  regulation: 'Regulation: ',
  CFDs: 'CFDs — ',
  futures: 'Futures — ',
  securitiesTrading: 'Securities Trading — ',
  companyPresence: 'Company presence: '
}

export interface IButtons {
  label: string,
  name: string,
  link?: string,
  img: string,
  class: string,
}

export interface IPlatform {
  name: string,
  link: string,
  score: number,
  deposit: string,
  founded: string,
  impact: string,
  impactMobile?: string,
  tools: string,
  regulation?: string,
  futuresRegulation?: string,
  platforms?: string,
  securitiesTrading?: string,
  color: string,
  haveApp?: string | IButtons[],
  haveAcc?: string | IButtons[],
  intro?: string,
  actions?: BUTTON_TYPES[]
  companyPresence?: string
}
export type IPlatformsInfo = {
  [key in PLATFORMS]: IPlatform
}
export const PLATFORMS_INFO: IPlatformsInfo = {
  [PLATFORMS.PLUS500]: {
    name: GENERAL_LINKS[PLATFORMS.PLUS500].name,
    link: GENERAL_LINKS[PLATFORMS.PLUS500].link,
    score: 4.1,
    deposit: '$100',
    founded: '2008',
    impact: 'Innovation and Entrepreneurship initiatives, ongoing collaboration with top tier academic institutions.',
    tools: 'Extended trading hours/ Unlimited demo/ Trading Academy',
    regulation: 'FCA, CySec 250/14, ASIC, FMA, ISA, FSCA, FSA, EFSA, MAS, DFSA; ',
    futuresRegulation: 'CFTC (USA)',
    platforms: 'Plus500 WebTrader/ Plus500 App/ Plus500 Invest/ Plus500 Futures platform (US citizens only)',
    color: 'white',
    actions: [
      BUTTON_TYPES.GET_APP_GLOBAL_LINK,
      BUTTON_TYPES.GET_APP_USA_LINK,
      BUTTON_TYPES.OPEN_ACCOUNT_GLOBAL_LINK,
      BUTTON_TYPES.OPEN_ACCOUNT_USA_LINK,
    ],
  },
  [PLATFORMS.ETORO]: {
    name: GENERAL_LINKS[PLATFORMS.ETORO].name,
    link: GENERAL_LINKS[PLATFORMS.ETORO].link,
    score: 4.3,
    deposit: '$100',
    founded: '2007',
    impact: 'Environmental Investing, Innovation initiatives, GoodDollar Project, Charity Donations.',
    tools: 'Trading Academy, eToro Plus: In-Depth Analysis, Social Investing, Delta Investment Tracker, ESG scoring, TradingView charts, Extended trading hours',
    regulation: 'FCA, CySec, ASIC, FSAS, FSRA, FSMR; ',
    platforms: 'Multi-asset platform/ eToro App/ eToro Money crypto wallet/ eToro Options App (for US citizens only)',
    securitiesTrading: 'member of FINRA and SIPC',
    color: '#0CC635',
    actions: [
      BUTTON_TYPES.OPEN_ACCOUNT_GLOBAL_LINK,
      BUTTON_TYPES.OPEN_ACCOUNT_USA_LINK,
    ],
  },
  [PLATFORMS.PEPPERSTONE]: {
    name: GENERAL_LINKS[PLATFORMS.PEPPERSTONE].name,
    link: GENERAL_LINKS[PLATFORMS.PEPPERSTONE].link,
    score: 4.5,
    deposit: '$250',
    founded: '2010',
    impact: 'Pepperstone Pledge – global Environmental, Social and Governance program',
    tools: 'Extended trading hours/ The Trade Off TV show and podcasts',
    regulation: 'ASIC, BaFin, FCA, CySEC, SCB, CMA, DFSA',
    platforms: 'MT4/ MT5/ cTrader/ TradingView',
    color: 'white',
    actions: [BUTTON_TYPES.OPEN_ACCOUNT_GLOBAL_LINK],
  },
  [PLATFORMS.BLACKBULL_MARKETS]: {
    name: GENERAL_LINKS[PLATFORMS.BLACKBULL_MARKETS].name,
    link: GENERAL_LINKS[PLATFORMS.BLACKBULL_MARKETS].link,
    score: 4.5,
    deposit: 'n/a',
    founded: '2014',
    impact: 'Sponsorship to support pediatric healthcare, Auckland University prize to award',
    tools: 'Education Hub including trading videos, podcasts, webinars, and market analysis',
    regulation: 'FMA (New Zealand), FSA',
    platforms: 'MT4/ MT5/ TradingView/ BlackBull Trade/ BlackBull CopyTrader/ BlackBull Shares',
    color: 'black',
    actions: [BUTTON_TYPES.OPEN_ACCOUNT_GLOBAL_LINK],
  },
  [PLATFORMS.AVA_TRADE]: {
    name: GENERAL_LINKS[PLATFORMS.AVA_TRADE].name,
    link: GENERAL_LINKS[PLATFORMS.AVA_TRADE].link,
    score: 4.7,
    deposit: '$100',
    founded: '2007',
    impact: 'Women Support initiatives pledging to help make a crack and balance the scales between the genders.',
    tools: 'News/ market analysis/ live webinars',
    regulation: 'ASIC, FSCA, FFAJ, FRSA, FSC, CySEC, ISA, the Central Bank of Ireland',
    platforms: 'MT4/ MT5/ WebTrader/ AvaOptions/ AvaTradeGO/ AvaSocial',
    color: 'white',
    actions: [
      BUTTON_TYPES.OPEN_ACCOUNT_GLOBAL_LINK,
      BUTTON_TYPES.GOOGLE_PLAY_LINK,
      BUTTON_TYPES.APP_STORE_LINK,
    ],
  },
  [PLATFORMS.INTER_TRADER]: {
    name: GENERAL_LINKS[PLATFORMS.INTER_TRADER].name,
    link: GENERAL_LINKS[PLATFORMS.INTER_TRADER].link,
    score: 4.7,
    deposit: '$250',
    founded: '2009',
    impact: 'Access to Prime of Prime services for retail investors.',
    tools: 'News/ market insights/ Market Buzz',
    regulation: 'Gibraltar FSC/ FCA, Mauritius FSC',
    platforms: 'MT4/ MT5/ Intertrader+',
    color: 'black',
    actions: [BUTTON_TYPES.OPEN_ACCOUNT_GLOBAL_LINK]
  },
  [PLATFORMS.EXNESS]: {
    name: GENERAL_LINKS[PLATFORMS.EXNESS].name,
    link: GENERAL_LINKS[PLATFORMS.EXNESS].link,
    score: 4.6,
    deposit: '$10',
    founded: '2008',
    impact: 'CSR projects and volunteering in Education, Environment and Emergencies – student scholarships, fighting COVID-19 and forest fires.',
    impactMobile: 'CSR projects and volunteering in Education, Environment and Emergencies.',
    tools: 'News/ market analysis/ video tutorials',
    regulation: 'FCA, FSA, CySEC, FSCA, FSC, Central Bank of Curacao and Saint Maarten',
    platforms: 'MT4/ MT5/ WebTrader/ ExnessTrade',
    color: '#FFDF05',
    actions: [
      BUTTON_TYPES.OPEN_ACCOUNT_GLOBAL_LINK,
      BUTTON_TYPES.GOOGLE_PLAY_LINK,
      BUTTON_TYPES.APP_STORE_LINK,
    ],
  },
  [PLATFORMS.YOU_HODLER]: {
    name: GENERAL_LINKS[PLATFORMS.YOU_HODLER].name,
    link: GENERAL_LINKS[PLATFORMS.YOU_HODLER].link,
    score: 4.2,
    deposit: '$10',
    founded: '2018',
    impact: 'Initiatives aimed at advancing blockchain education and fostering blockchain adoption globally.',
    tools: 'Crypto loans/ % yield account/ cloud miner',
    platforms: 'MultiHODL',
    color: 'white',
    intro: 'A multi-faced FinTech platform regulated EU and Swiss Financial institutions providing a variety of Web3 crypto and fiat services',
    companyPresence: 'Switzerland',
    actions: [
      BUTTON_TYPES.GOOGLE_PLAY_LINK,
      BUTTON_TYPES.APP_STORE_LINK,
    ],
  },
}
