import React, { useCallback, useMemo } from 'react'

import { TEXTS } from '../../../../assets/content/texts'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg'
import FlagUsa from '../../../../assets/images/flag_usa.png'
import FlagGlobal from '../../../../assets/images/flagGlobal.png'
import { ACTION_TYPES, BUTTON_TYPES, PLATFORMS } from '../../../../assets/constants'
import { IMain, IModal } from '../../index'
import { ButtonLink } from '../Button'

import './styles.css'

const ButtonImageTitle = ({ action }: { action: BUTTON_TYPES }) => {
  const isItUsa = useMemo(() => (action.includes('USA')), [action])
  const text = useMemo(() => (isItUsa ? TEXTS['usa'] : TEXTS['global']), [isItUsa])
  return (
    <div className="HalfButtonTitle">
      <img className="HalfButtonImg" src={isItUsa ? FlagUsa : FlagGlobal} alt={`flag img for ${text}`} />
      {text}
    </div>
  )
}
const FlagButton = ({ action, platform, actionType }: { action: BUTTON_TYPES, platform: PLATFORMS, actionType: ACTION_TYPES }) => (
  <div key={action} className="HalfButton">
    <ButtonImageTitle action={action} />
    <ButtonLink platform={platform} action={action} actionType={actionType} />
  </div>
)

export const Modal = ({ modal: { actions, platform }, closeModal }: { modal: IModal, closeModal: IMain['closeModal'] }) => {
  const isItStoreButtons =  useMemo(() => actions.some((action) => action.includes(BUTTON_TYPES.GOOGLE_PLAY_LINK)), [actions])

  const renderButton = useCallback((action: BUTTON_TYPES) => {
    const actionType =  action.includes(ACTION_TYPES.GET_APP) ? ACTION_TYPES.GET_APP : ACTION_TYPES.OPEN_ACCOUNT
    if (isItStoreButtons) return <ButtonLink key={action} action={action} platform={platform} actionType={actionType} noText />
    return <FlagButton key={action} action={action}  platform={platform} actionType={actionType} />
  }, [isItStoreButtons, platform])

  return (
    <div className="ModalWrap" onClick={closeModal}>
      <div className={`Modal ${isItStoreButtons ? 'Store' : ''}`}>
        <CloseIcon onClick={closeModal} className="CloseIcon" />
        {actions.map(renderButton)}
      </div>
    </div>
  )
}
