import React, { useCallback, useEffect, useState } from 'react'

import { BUTTON_TYPES, PLATFORMS, VIEW_TYPE } from '../../assets/constants'
import { Modal, MobileView, StandardView } from './components'

import './styles.css'

export interface IModal {
  actions: BUTTON_TYPES[],
  platform: PLATFORMS
}
export interface IMain {
  view: VIEW_TYPE,
  modal: IModal | undefined,
  openModal: (modal: IModal) => void,
  closeModal: () => void,
}

const Main = () => {
  const [view, setView] = useState<VIEW_TYPE>(VIEW_TYPE.MOBILE)

  const [modal, setModal] = useState<IMain['modal']>(undefined)
  const openModal = useCallback<IMain['openModal']>((data) => setModal(data), [])
  const closeModal = useCallback<IMain['closeModal']>(() => setModal(undefined), [])

  const handleResize = useCallback(() => {
    const w = window.innerWidth
    if (w > 1279) setView(VIEW_TYPE.DESKTOP)
    else if (w > 641) setView(VIEW_TYPE.TABLET)
    else setView(VIEW_TYPE.MOBILE)
  }, [])

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [handleResize])
  useEffect(() => handleResize())

  return (
    <div className="Main">

      { !!modal && <Modal modal={modal} closeModal={closeModal} />}

      {
        view === VIEW_TYPE.MOBILE
        ? <MobileView openModal={openModal} />
        : <StandardView openModal={openModal} view={view} />
      }

    </div>
  )
}

export default Main
