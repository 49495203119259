import React from 'react'
import { Link } from 'react-router-dom'

import { PLATFORMS } from '../../../../assets/constants'
import { TEXTS } from '../../../../assets/content/texts'
import { GENERAL_LINKS } from '../../../../assets/content/links'
import { URLS } from '../../../../App'
import { IMain } from '../../index'
import { MobileCard } from '../Platform/MobileCard'
import { Reddit } from '../Reddit'

import '../../styles.css'

interface IMobileView {
  openModal: IMain['openModal'],
}

export const MobileView = ({ openModal }: IMobileView) => (
  <>
    <div className="Top">
      <div className="Logo" />
      <div>
        <div className="Title">
          {TEXTS.header1}
          <br />
          {TEXTS.header2}
        </div>
      </div>
    </div>
    <div className="MobileSubHeader">
      {TEXTS.mobileSubHeader}
    </div>
    <div className="Content">
      <div className="Platforms">
        {Object.values(PLATFORMS).map((platform: PLATFORMS) => (
          <MobileCard key={platform} platform={platform} openModal={openModal} />
        ))}
      </div>
    </div>

    <div className="AdvertisementInFooter">
      <Reddit />

      <div className="CardLabel">{TEXTS.ratedBasedOn}</div>
      <div className="Card">{TEXTS.ratingDescription}</div>

      <div className="PrivacyPolicy">
        <Link to={URLS.conditions()} target="_blank" rel="noopener noreferrer">{TEXTS.conditions}</Link>
        <Link to={URLS.privacyPolicy()} target="_blank" rel="noopener noreferrer">{TEXTS.privacyPolicy}</Link>
      </div>
    </div>

    <div className="Bottom">
      <div className="Card MobileDisclaimer">
        { TEXTS.disclaimer }
      </div>
      <div className="Card BottomMenu">
        <Link to={GENERAL_LINKS.blog.link} target='_blank' rel="noreferrer">
          <div className="MenuLink">{GENERAL_LINKS.blog.name}</div>
        </Link>
        <div className="Separator" />
        <Link to={GENERAL_LINKS.about.link} target='_blank' rel="noreferrer">
          <div className="MenuLink"> {GENERAL_LINKS.about.name}</div>
        </Link>
        <div className="Separator" />
        <Link to={GENERAL_LINKS.contact.link} target='_blank' rel="noreferrer">
          <div className="MenuLink"> {GENERAL_LINKS.contact.name}</div>
        </Link>
      </div>
    </div>
  </>
)

