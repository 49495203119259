import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'

import { ACTION_TYPES, BUTTON_TYPES, PLATFORMS } from '../../../../assets/constants'
import { APP_ACC_LINKS } from '../../../../assets/content/links'
import { TEXTS } from '../../../../assets/content/texts'

export const ModalButton = ({ action, actionType, onClick }: { action: BUTTON_TYPES, onClick: (actionType: ACTION_TYPES) => void, actionType: ACTION_TYPES }) => {
  const handleClick = useCallback(() => onClick(actionType), [actionType, onClick])
  return <button className={action} onClick={handleClick} >{TEXTS[actionType]}</button>
}

export const ButtonLink = ({ platform, action, actionType, noText }: { platform: PLATFORMS, action: BUTTON_TYPES, actionType: ACTION_TYPES, noText?: boolean }) => (
  <Link to={APP_ACC_LINKS[platform][action] || ''} target="_blank" rel="noreferrer">
    <button className={action}>{!noText && TEXTS[actionType]}</button>
  </Link>
)

