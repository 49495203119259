import React from 'react'

import FilledStar from '../../../../assets/icons/filled_star.svg'
import EmptyStar from '../../../../assets/icons/empty_star.svg'
import PartialStar1 from '../../../../assets/icons/star1.svg'
import PartialStar2 from '../../../../assets/icons/star2.svg'
import PartialStar3 from '../../../../assets/icons/star3.svg'
import PartialStar4 from '../../../../assets/icons/star4.svg'
import PartialStar5 from '../../../../assets/icons/star5.svg'
import PartialStar6 from '../../../../assets/icons/star6.svg'
import PartialStar7 from '../../../../assets/icons/star7.svg'
import PartialStar8 from '../../../../assets/icons/star8.svg'
import PartialStar9 from '../../../../assets/icons/star9.svg'

function getImages(score: number): { img: typeof PartialStar1, starScore: number }[] {
  const images = [];
  // Количество полностью заполненных картинок
  const fullImagesCount = Math.floor(score)
  // Количество частично заполненной картинки
  const partialImageCount = score % 1

  // Добавляем полностью заполненные картинки
  for (let i = 0; i < fullImagesCount; i++) {
    images.push({ img: FilledStar, starScore: 1 })
  }

  // Добавляем частично заполненную картинку, если есть
  if (partialImageCount > 0) {
    const starScore = Math.round(partialImageCount * 10)
    const partialStar = [PartialStar1, PartialStar2, PartialStar3, PartialStar4, PartialStar5, PartialStar6, PartialStar7, PartialStar8, PartialStar9][starScore - 1]
    images.push( { img: partialStar, starScore: starScore })
  }

  // Добавляем пустые картинки, чтобы получить массив из 5 элементов
  const emptyImagesCount = 5 - images.length
  for (let i = 0; i < emptyImagesCount; i++) {
    images.push( { img: EmptyStar, starScore: 0 })
  }

  return images;
}

export const RatingStars = ({ score }: { score: number }) => {
  const img = getImages(score)
  return (
   <div className="Stars">
     {img.map((el, index) => (
       <img key={`${index}_${el.starScore}`} src={el.img} alt={`star for rating ${el.starScore}`} />
     ))}
   </div>
  )
}
