import React, { useCallback, useMemo } from 'react'

import { ACTION_TYPES, BUTTON_TYPES, PLATFORMS } from '../../../../assets/constants'
import { IMain } from '../../index'
import {  ButtonLink, ModalButton } from '../Button'

export const Buttons = ({ platform, actions, openModal }: { platform: PLATFORMS, actions: BUTTON_TYPES[], openModal: IMain['openModal'] }) => {
  const parsedActions = useMemo(() => ({
    [ACTION_TYPES.GET_APP]: actions.filter((action) => action.includes(ACTION_TYPES.GET_APP)),
    [ACTION_TYPES.OPEN_ACCOUNT]: actions.filter((action) => action.includes(ACTION_TYPES.OPEN_ACCOUNT)),
  }), [actions])

  const singleButton = useMemo(() => (!parsedActions[ACTION_TYPES.GET_APP].length || !parsedActions[ACTION_TYPES.OPEN_ACCOUNT].length), [parsedActions])

  const handleOpenModal = useCallback((actionType: ACTION_TYPES) => openModal({ actions: parsedActions[actionType], platform }), [platform, openModal, parsedActions])

  const renderButton = useCallback((actionType: ACTION_TYPES) => {
    const items = parsedActions[actionType]
    if (!items.length) return null
    const action = items[0]

    if (items.length === 1) return <ButtonLink platform={platform} action={action} actionType={actionType} />
    return <ModalButton action={action} actionType={actionType} onClick={handleOpenModal} />
  }, [platform, handleOpenModal, parsedActions])

  return (
    <div className={`Buttons ${ singleButton ? "Single" : "" }`}>
      {renderButton(ACTION_TYPES.GET_APP)}
      {renderButton(ACTION_TYPES.OPEN_ACCOUNT)}
    </div>
  )
}
